import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Globals from '../../config/globals';
import ribbonLogo from '../../img/D2P-logo-show-sm.png';
import { smallBox, SmartMessageBox } from "../../common/utils/functions";
import $ from 'jquery';

class LeadRetrievalNav extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showOnMobile: false,
            formFields: {
                company_id: Globals.userInfo.companyId
            },
            msgType: 'initial',
            lastGoodMenuId: ''
        }
    }

    componentDidMount() {
        //this.handleNavigationChange();

        $('.nav li').removeClass("active-nav-item");
        $("#showLeadsAdministration").addClass("active-nav-item");

        var path = '/show-leads-admin';
        this.props.history.replace(path);
    }

    handleNavigationChange = () => {
        console.log("this.props.location.pathname: ", this.props.location.pathname);

        $('.nav li').removeClass("active-nav-item");
        switch (this.props.location.pathname) {
            case '/lead-retrieval-admin':
                $("#manageAppContent").addClass("active-nav-item");
                break;
            case '/show-leads-admin':
                $("#showLeadsAdministration").addClass("active-nav-item");
                break;
            default:
                break;
        }
    }

    handleNavigationClick = (event, id) => {
        //console.log("event.target.id: ", event.target.id);

        event.preventDefault();
        let path = '';
        $('.nav li').removeClass("active-nav-item");

        switch (event.target.id) {
            case 'manageAppContent':
                path = '/lead-retrieval-admin';
                this.props.history.replace(path);
                $("#manageAppContent").addClass("active-nav-item");
                break;
            case 'showLeadsAdministration':
                path = '/show-leads-admin';
                this.props.history.replace(path);
                $("#showLeadsAdministration").addClass("active-nav-item");
                break;
            case 'newApiTester':
                path = '/new-api-tester';
                this.props.history.replace(path);
                $("#newApiTester").addClass("active-nav-item");
                break;
            default:
                break;
        }
    }

    handleLogout = (event) => {
        SmartMessageBox(
            {
                title: "You are about to logout.",
                content:
                    "Are you sure you would like to proceed?",
                buttons: "[No][Yes]"
            },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    event.preventDefault();
                    let path = '';
                    path = '/';

                    Globals.isDirtyPage = false;
                    Globals.userInfo = {};
                    this.props.history.replace(path);
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    render() {
        return (
            <div className="page-sidebar">
                <ul className="nav nav-tabs nav-clients nav-stacked flex-column" role="tablist">
                    <li className="text-center pt-5 pb-5">
                        <img src={ribbonLogo} width="75%"/>
                    </li>
                    <li className="nav-item" id="showLeadsAdministration" onClick={this.handleNavigationClick}>
                        <a className="nav-link fs-lg px-4" id="showLeadsAdministration" onClick={this.handleNavigationClick}>
                            <span className="hidden-sm-down ml-1" id="showLeadsAdministration" onClick={this.handleNavigationClick}>
                                Show Leads Administration
                            </span>
                        </a>
                    </li>
                    <li className="nav-item" id="manageAppContent" onClick={this.handleNavigationClick}>
                        <a className="nav-link fs-lg px-4" id="manageAppContent" onClick={this.handleNavigationClick}>
                            <span className="hidden-sm-down ml-1" id="manageAppContent" onClick={this.handleNavigationClick}>
                                Manage D2P App Content
                            </span>
                        </a>
                    </li>
                    {/*<li className="nav-item" id="newApiTester" onClick={this.handleNavigationClick}>*/}
                    {/*    <a className="nav-link fs-lg px-4" id="newApiTester" onClick={this.handleNavigationClick}>*/}
                    {/*        <span className="hidden-sm-down ml-1" id="newApiTester" onClick={this.handleNavigationClick}>*/}
                    {/*            New API Tester*/}
                    {/*        </span>*/}
                    {/*    </a>*/}
                    {/*</li>*/}

                    <div>
                        <span className="font-weight-bold header-icon mimicA top-nav-item font-size-large" onClick={this.handleLogout} title={'Logout, ' + Globals.userInfo.fullName}>
                            <span className="text-icon">LOGOUT</span>&nbsp;
                            <i className="fas fa-sign-out-alt logoutIcon text-icon"></i>
                        </span>
                    </div>
                </ul>
            </div>
        )
    }
}

export default withRouter(LeadRetrievalNav);