import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import ExhibitorSearch from './ExhibitorSearch';
import QRCode from 'qrcode.react';
import { toast, confirm } from '@rickylandino/react-messages';
import logo from '../../img/D2P-logo-show-login-sm.png';

function NewApiTester(props) {
    const [state, setState] = useState({
        
    });

    useEffect(() => {
        
    }, []);

    function handleVerifyAPIKey() {
        Axios.get(`/api/VerifyAPIKey`, {
            params: {
                apiKey: 'D2P@16W@t3rburY'
            }
        }).then(response => {
            if (response.data.includes('verified')) {
                toast.success(response.data);
            }
            else {
                toast.error(response.data);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function handleGetActiveShowsForD2PApp() {
        Axios.get(`/api/GetActiveShowsForD2PApp`, {
            params: {
                apiKey: 'D2P@16W@t3rburY'
            }
        }).then(response => {
            console.log(response.data);

            toast.info('Active D2P App Shows returned to console');

        }).catch(error => {
            console.log(error);
        });
    }

    function handleGetAppControlInfo() {
        Axios.get(`/api/GetAppControlInfo`, {
            params: {
                apiKey: 'D2P@16W@t3rburY'
            }
        }).then(response => {
            console.log(response.data);

            toast.info('D2P App Control Info returned to console');

        }).catch(error => {
            console.log(error);
        });
    }

    function handleRegisterExhibitor() {
        Axios.get(`/api/RegisterExhibitor`, {
            params: {
                apiKey: 'D2P@16W@t3rburY',
                exhibitorid: 3547,
                showcode: 380,
                deviceid: 'DNPDVUBN0D84'
            }
        }).then(response => {
            if (response.data.includes('Successful')) {
                toast.success(response.data);
            }
            else if (response.data.includes('Failed')) {
                toast.error(response.data);
            }
            else {
                toast.info(response.data);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function handleValidateExhibitor() {
        Axios.get(`/api/ValidateExhibitor`, {
            params: {
                apiKey: 'D2P@16W@t3rburY',
                exhibitorid: 3547
            }
        }).then(response => {
            if (response.data.includes('Success')) {
                toast.success('Exhibitor Validated Successfully');
            }
            else {
                toast.error('Exhibitor Not Found');
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function handleGetHallImageFileFromDB() {
        Axios.get(`/api/GetHallImageFileFromDB`, {
            params: {
                apiKey: 'D2P@16W@t3rburY',
                showcode: 380
            }
        }).then(response => {
            console.log(response.data);

            toast.info('Hall Image returned to console');
        }).catch(error => {
            console.log(error);
        });
    }

    function handleProcessLeadsToD2P() {
        let myLeads = [
            { AppLeadID: 100, FName: 'Dave', LName: 'Landino', Company: 'Automated Concepts, LLC', Title: 'Owner', Email: 'dlandino@automated-concepts.com', Phone: '860-965-9916', Address: '42 Madison Way', City: 'South Windsor', State: 'CT', Zip: '06074', PurchasingRole: 'F', Timeframe: 'A', IndustryCode: 'Consultant', LeadGrade: '', Rep: '', FollowUp: '', LeadNotes: '', DeviceId: 'DNPDVUBN0D84', OriginalScan: '2024-07-30 06:44:01.473' },
            { AppLeadID: 101, FName: 'Hunter', LName: 'Ashner', Company: 'Automated Concepts, LLC', Title: '2nd In Command', Email: 'hashner@automated-concepts.com', Phone: '860-921-6542', Address: '81 VANDERPOEL AVE', City: 'Bantam', State: 'CT', Zip: '06750', PurchasingRole: 'S', Timeframe: 'B', IndustryCode: 'Consultant', LeadGrade: '', Rep: '', FollowUp: '', LeadNotes: '', DeviceId: 'DNPDVUBN0D85', OriginalScan: '2024-07-30 07:26:00.000' }
        ];

        const myJSON = JSON.stringify(myLeads);

        Axios.get(`/api/ProcessLeadsToD2P`, {
            params: {
                apiKey: 'D2P@16W@t3rburY',
                exhibitorid: 3547,
                showcode: 380,
                leads: myJSON
            }
        }).then(response => {
            if (response.data.includes('Success')) {
                toast.success('Leads Successfully Processed');
            }
            else {
                toast.error('Error Processing Leads');
            }
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div>
            <h3>New API Endpoint Tester</h3>
            <div className="row">
                <div className="col col-12">
                    <button type="button" class="btn btn-link" onClick={handleVerifyAPIKey}>VerifyAPIKey</button>
                </div>
                <div className="col col-12">
                    <button type="button" class="btn btn-link" onClick={handleGetActiveShowsForD2PApp}>GetActiveShowsForD2PApp</button>
                </div>
                <div className="col col-12">
                    <button type="button" class="btn btn-link" onClick={handleGetAppControlInfo}>GetAppControlInfo</button>
                </div>
                <div className="col col-12">
                    <button type="button" class="btn btn-link" onClick={handleRegisterExhibitor}>RegisterExhibitor</button>
                </div>
                <div className="col col-12">
                    <button type="button" class="btn btn-link" onClick={handleValidateExhibitor}>ValidateExhibitor</button>
                </div>
                <div className="col col-12">
                    <button type="button" class="btn btn-link" onClick={handleGetHallImageFileFromDB}>GetHallImageFileFromDB</button>
                </div>
                <div className="col col-12">
                    <button type="button" class="btn btn-link" onClick={handleProcessLeadsToD2P}>ProcessLeadsToD2P</button>
                </div>
            </div>
        </div>
    );
}

export default withRouter(NewApiTester);